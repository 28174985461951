/* src/components/css/ProfileOverview.css */

.profile-overview {
  background-color: #2b2b2b;
  color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 100vw;
  max-width: 640px;
  /* Maximum width on larger screens */
  margin: 20px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), 0 0 20px rgba(243, 246, 247, 0.2);
  box-sizing: border-box;
  /* Ensure padding is included in the width */
}

.profile-link {
  text-decoration: none;
  color: inherit;
}

.profile-header {
  display: flex;
  align-items: center;
}

.profile-avatar {
  width: 60px;
  height: 60px;
  background-color: #008080;
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), 0 0 20px rgba(243, 246, 247, 0.2);
}

.profile-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-info h1 {
  margin: 0;
  font-size: 1.4em;
}

.profile-info-highlights {
  display: flex;
}

.profile-info-highlights p {
  font-size: 0.8em;
  margin-right: 10px;
}

.profile-placeholder {
  display: flex;
  align-items: center;
}

.placeholder-avatar {
  width: 60px;
  height: 60px;
  background-color: #5c5c5c;
  border-radius: 50%;
  margin-right: 20px;
}

.placeholder-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.placeholder-name {
  width: 150px;
  height: 20px;
  background-color: #5c5c5c;
  margin-bottom: 10px;
  border-radius: 4px;
}

.placeholder-changesets {
  width: 100px;
  height: 20px;
  background-color: #5c5c5c;
  border-radius: 4px;
}

/* Media Queries for Mobile */
@media (max-width: 640px) {
  .profile-overview {
    width: 100vw;
    max-width: 95%;
    padding: 10px;
  }

  .profile-header {
    flex-direction: row;
    align-items: center;
  }

  .profile-avatar {
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }
}